// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-brand {
	color: #f8f9fa;
	display: block;
	font-size: 1.15rem;
	font-weight: 600;
	padding: 1.15rem 1.5rem
}

.sidebar-brand:hover {
	color: #f8f9fa;
	text-decoration: none
}

.sidebar-brand:focus {
	outline: 0
}`, "",{"version":3,"sources":["webpack://./src/css/Admin/test.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,cAAc;CACd,kBAAkB;CAClB,gBAAgB;CAChB;AACD;;AAEA;CACC,cAAc;CACd;AACD;;AAEA;CACC;AACD","sourcesContent":[".sidebar-brand {\n\tcolor: #f8f9fa;\n\tdisplay: block;\n\tfont-size: 1.15rem;\n\tfont-weight: 600;\n\tpadding: 1.15rem 1.5rem\n}\n\n.sidebar-brand:hover {\n\tcolor: #f8f9fa;\n\ttext-decoration: none\n}\n\n.sidebar-brand:focus {\n\toutline: 0\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
