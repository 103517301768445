// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    background-color: #067A38;
    color: #fff;
    padding: 30px 0;
    animation: fadeIn 1.5s ease-in-out;
}

footer p {
    margin: 0;
}

footer .fab {
    font-size: 1.5rem;
    color: #fff;
    margin: 0 10px;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

footer .fab:hover {
    color: #F28B13;
    transform: scale(1.1);
}
footer .col {
    margin-bottom: 20px;
}

footer a {
    color: white;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}

footer .social-icons img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/css/footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,kCAAkC;AACtC;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,cAAc;IACd,8DAA8D;AAClE;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":["footer {\n    background-color: #067A38;\n    color: #fff;\n    padding: 30px 0;\n    animation: fadeIn 1.5s ease-in-out;\n}\n\nfooter p {\n    margin: 0;\n}\n\nfooter .fab {\n    font-size: 1.5rem;\n    color: #fff;\n    margin: 0 10px;\n    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;\n}\n\nfooter .fab:hover {\n    color: #F28B13;\n    transform: scale(1.1);\n}\nfooter .col {\n    margin-bottom: 20px;\n}\n\nfooter a {\n    color: white;\n    text-decoration: none;\n}\n\nfooter a:hover {\n    text-decoration: underline;\n}\n\nfooter .social-icons img {\n    width: 50px;\n    height: 50px;\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
